export const environment = {
  production: false,
  apiProjectUrl: "https://a314-projects-dev.thevillageph.avwaveinteractive.com/projects",
  apiUrlAdmin:
    "https://a314-admin-users-dev.thevillageph.avwaveinteractive.com/admin-users",
  apiGoogleStorage:
    "https://a314-google-storage-dev.thevillageph.avwaveinteractive.com/storage",
  urlStorage: "https://storage.googleapis.com/avtvp-avwave-dev/",
  testUser: {
    // tslint:disable-line
    token: "cualquiercosa",
    email: "user@user.user",
  },
};
